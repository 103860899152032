import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import React from 'react';
// import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';

import globals from '../../../../globals';
import Styles from './blogPostHeader.styles';

const BlogPostHeader = ({ post }) => {
  const data = useStaticQuery(graphql`
    query BlogPostHeader {
      fallbackImage: file(relativePath: { eq: "backgrounds/bg-fallback.jpg" }) {
        ...imageFluidExtraLarge
      }
    }
  `);

  let backgroundFluidImageStack = [
    post.postBlog.gallery[0].localFile.childImageSharp.fluid ||
      data.fallbackImage.childImageSharp.fluid,
    globals.backgroundOverlay,
  ].reverse();

  return (
    <Styles>
      <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
        <div className="content">
          <Container className="d-flex align-items-center pt-7 pb-6">
            <div className="d-flex flex-column">
              {/*{post.categories.nodes?.length > 0 && (
                <div>
                  {post.categories.nodes.map((category) => (
                    <Link to={`/${globals.blogURI}/${category.slug}/`} key={category.id}>
                      {category.name}
                    </Link>
                    <span key={category.id}>{category.name}</span>
                  ))}
                </div>
              )}*/}
              <div className="display-1 h1 mb-2">{post.title}</div>
              <p className="lead mb-0 w-75">
                {post.geographies.nodes?.length > 0 && (
                  <div>
                    {post.geographies.nodes.map((geography) => (
                      /*<Link to={`/${globals.blogURI}/${geography.slug}/`} key={geography.id}>
                        {geography.name}
                      </Link>*/
                      <span key={geography.id}>{geography.name}</span>
                    ))}
                  </div>
                )}
              </p>
            </div>
          </Container>
        </div>
      </BackgroundImage>
    </Styles>
  );
};

export default BlogPostHeader;

BlogPostHeader.propTypes = {
  post: PropTypes.object.isRequired,
};
