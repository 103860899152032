import { graphql, Link, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Card } from 'react-bootstrap';

import globals from '../../../globals';

export const cardPost = graphql`
  fragment cardPost on WpPost {
    title
    uri
    content
    seo {
      metaDesc
    }
    featuredImage {
      node {
        localFile {
          ...imageFluidMedium
        }
      }
    }
    categories {
      nodes {
        name
      }
    }
    geographies {
      nodes {
        name
      }
    }
    postBlog {
      gallery {
        localFile {
          ...imageFluidMedium
        }
      }
    }
  }
`;

const PostCard = ({ post }) => {
  const data = useStaticQuery(graphql`
    query PostCardQuery {
      fallbackImage: file(relativePath: { eq: "backgrounds/bg-fallback.jpg" }) {
        ...imageFluidMedium
      }
    }
  `);

  function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + '&hellip;' : str;
  }

  return (
    <Link to={`/${globals.blogURI}${post.uri}`}>
      <Card className="post-card">
        <BackgroundImage
          className="card-img"
          as="header"
          fluid={
            post.featuredImage?.node.localFile.childImageSharp.fluid ||
            post.postBlog.gallery[0].localFile.childImageSharp.fluid ||
            data.fallbackImage.childImageSharp.fluid
          }
        >
          <div className="image-overlay">
            <div>
              <h2>{post.title}</h2>
            </div>
          </div>

          <div className="card-meta">
            <Badge pill variant="light">
              {post.geographies.nodes[0]?.name}
            </Badge>
            {` `}
            <Badge pill variant="light">
              {post.categories.nodes[0]?.name}
            </Badge>
          </div>
        </BackgroundImage>

        <Card.Text>
          {post.seo.metaDesc.length > 1
            ? post.seo.metaDesc
            : truncate(post.content, 120).replace(/<\/?[^>]+(>|$)/g, '')}
        </Card.Text>
      </Card>
    </Link>
  );
};

export default PostCard;

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
};
