import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import PostCard from '../../postCard/postCard';

const BlogPostRelatedPosts = ({ posts }) => {
  const data = useStaticQuery(graphql`
    query RelatedPosts {
      wp {
        sharedSiteContent {
          siteBlogCopy {
            relatedPostsHeadline
          }
        }
      }
    }
  `);

  return (
    <section>
      <h2 className="text-center mb-4">
        {data.wp.sharedSiteContent.siteBlogCopy.relatedPostsHeadline}
      </h2>
      <Container fluid={true}>
        <Row>
          {posts.map((post, index) => (
            <Col xs={12} md={4} className="mb-4" key={index}>
              <PostCard post={post.post} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default BlogPostRelatedPosts;

BlogPostRelatedPosts.propTypes = {
  posts: PropTypes.array.isRequired,
};
