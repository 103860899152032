import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-scroll';

const BlogPostGuideTableOfContents = ({ sections }) => {
  return (
    <Row className="mt-2 mt-lg-4">
      <Col>
        <h2 className="h6 my-3">Table Of Contents</h2>
        <ul className="list-unstyled m-0 table-of-contents">
          {sections.map((section, index) => (
            <li className="mb-2" key={index}>
              <Link
                to={`section${index}`}
                spy={true}
                smooth={true}
                duration={500}
                offset={-80}
              >
                {section.title}
              </Link>
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

export default BlogPostGuideTableOfContents;

BlogPostGuideTableOfContents.propTypes = {
  sections: PropTypes.array,
};
