import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const BlogPostAuthor = ({ author }) => {
  return (
    <aside className="bg-light mt-4 p-3">
      <Row className="align-items-center no-gutters">
        {author.userAuthorProfile.headshot?.localFile.id ||
          (author.avatar && (
            <Col xs={2} sm={3}>
              {author.userAuthorProfile.headshot ? (
                <GatsbyImage
                  image={
                    author.userAuthorProfile.headshot.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={author.name}
                  className="rounded-circle w-100"
                />
              ) : (
                <img
                  src={author.avatar.url}
                  alt={author.name}
                  className="rounded-circle w-100"
                />
              )}
            </Col>
          ))}
        <Col className="pl-3">
          <h2 className="h5">
            {author.name}
            {author.userAuthorProfile.website && (
              <a
                href={author.userAuthorProfile.website}
                target="_blank"
                style={{ textDecoration: 'underline' }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'external-link']}
                  className="ml-2 d-inline-block"
                />
              </a>
            )}
          </h2>
          <p className="small mb-0" style={{ lineHeight: '1.33' }}>
            {author.description}
          </p>
        </Col>
      </Row>
    </aside>
  );
};

export default BlogPostAuthor;

BlogPostAuthor.propTypes = {
  author: PropTypes.array,
};
