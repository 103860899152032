import styled from '@emotion/styled';

const Styles = styled.article({
  scrollBehavior: 'smooth',

  '.table-of-contents': {
    a: {
      opacity: '50%',

      '&:hover': {
        opacity: '75%',
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },

    '.active': {
      opacity: '100%',
    },
  },
});

export default Styles;
