import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import EscapeCard from '../../escapeCard/escapeCard';

const BlogPostRelatedEscapes = ({ escapes }) => {
  const data = useStaticQuery(graphql`
    query RelatedEscapes {
      wp {
        sharedSiteContent {
          siteBlogCopy {
            relatedEscapesHeadline
          }
        }
      }
    }
  `);

  return (
    <section>
      <h2 className="text-center mb-4">
        {data.wp.sharedSiteContent.siteBlogCopy.relatedEscapesHeadline}
      </h2>
      <Container fluid={true}>
        <Row>
          {escapes.map((escape, index) => (
            <Col xs={12} md={4} className="mb-4" key={index}>
              <EscapeCard escape={escape.escape} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default BlogPostRelatedEscapes;

BlogPostRelatedEscapes.propTypes = {
  escapes: PropTypes.array.isRequired,
};
