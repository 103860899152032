import PropTypes from 'prop-types';
import React from 'react';

import Wysiwyg from '../../wysiwyg/wysiwyg';

const BlogPostGuideSections = ({ guide }) => {
  return (
    <div className="my-5">
      {guide.sections.map((section, index) => (
        <div id={`section${index}`} className={`mb-5`} key={index}>
          <h2 className="mb-4">{section.title}</h2>
          <Wysiwyg html={section.content} />
        </div>
      ))}
      <Wysiwyg html={guide.conclusion} />
    </div>
  );
};

export default BlogPostGuideSections;

BlogPostGuideSections.propTypes = {
  guide: PropTypes.object,
};
