import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import globals from '../../globals';
import BlogPostAuthor from '../components/blogPost/blogPostAuthor/blogPostAuthor';
import BlogPostGuideSections from '../components/blogPost/blogPostGuide/blogPostGuideSections';
import BlogPostGuideTableOfContents from '../components/blogPost/blogPostGuide/blogPostGuideTableOfContents';
import BlogPostHeader from '../components/blogPost/blogPostHeader/blogPostHeader';
import BlogPostRelatedEscapes from '../components/blogPost/blogPostRelatedEscapes/blogPostRelatedEscapes';
import BlogPostRelatedPosts from '../components/blogPost/blogPostRelatedPosts/blogPostRelatedPosts';
import Gallery from '../components/gallery/gallery';
import Layout from '../components/layout/layout';
import NewsletterBanner from '../components/newsletterBanner/newsletterBanner';
import ScrollTopButton from '../components/scrollTopButton/scrollTopButton';
import SeoPress from '../components/seoPress/seoPress';
import Share from '../components/share/share';
import Wysiwyg from '../components/wysiwyg/wysiwyg';
import rhythm from '../utils/rhythm';
import Styles from './blog-post.styles';

const PostTemplate = ({ data }) => {
  // Share modal controls
  const [showShare, setShowShare] = useState(false);
  const handleCloseShare = () => setShowShare(false);
  const handleShowShare = () => setShowShare(true);

  // Gallery modal controls
  const [showGallery, setShowGallery] = useState(false);
  const handleCloseGallery = () => setShowGallery(false);
  const handleShowGallery = () => setShowGallery(true);

  return (
    <Layout>
      <SeoPress
        seo={data.allWpPost.nodes[0].seo}
        title={data.allWpPost.nodes[0].title}
        uri={`${globals.blogURI}${data.allWpPost.nodes[0].uri}`}
        featuredImage={
          data.allWpPost.nodes[0].featuredImage?.node.localFile.publicURL ||
          data.allWpPost.nodes[0].postBlog.gallery[0].localFile.publicURL
        }
        author={data.allWpPost.nodes[0].author.node.name}
      />

      <Styles>
        <BlogPostHeader post={data.allWpPost.nodes[0]} />

        <Container className="content pt-4">
          <Row>
            {/* Article */}
            <Col
              xs={{ span: 12, order: 'last' }}
              lg={{ span: 8, order: 'first' }}
            >
              {/* Post Content */}
              <Wysiwyg html={data.allWpPost.nodes[0].content} />

              {/* Guide Content */}
              {data.allWpPost.nodes[0].postBlog.postFormat === 'Guide' && (
                <BlogPostGuideSections
                  guide={data.allWpPost.nodes[0].postBlog.guide}
                />
              )}

              {/* Author Blurb */}
              {data.allWpPost.nodes[0].author.node.description && (
                <BlogPostAuthor author={data.allWpPost.nodes[0].author.node} />
              )}
            </Col>

            {/* Sidebar */}
            <Col
              xs={{ span: 12, order: 'first' }}
              lg={{ span: 4, order: 'last' }}
            >
              <div
                className="sticky-top pb-3 pb-lg-0"
                style={{ top: rhythm(2) }}
              >
                <Row className="justify-content-center">
                  {/* Share Button */}
                  <Col xs={6} lg={12}>
                    <Button
                      variant="light"
                      className="d-block w-100 btn-blog-sidebar"
                      onClick={handleShowShare}
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'share']}
                        className="d-none d-lg-inline-block"
                      />
                      &nbsp;Share
                    </Button>
                  </Col>

                  {/* Gallery Button */}
                  {data.allWpPost.nodes[0].postBlog.gallery.length > 1 && (
                    <Col xs={6} lg={12}>
                      <Button
                        variant="light"
                        className="d-block w-100 btn-blog-sidebar mt-0 mt-lg-3"
                        onClick={handleShowGallery}
                      >
                        <FontAwesomeIcon
                          icon={['fal', 'images']}
                          className="d-none d-lg-inline-block"
                        />
                        &nbsp;Gallery
                      </Button>
                    </Col>
                  )}
                </Row>

                {/* Guide Table Of Contents */}
                {data.allWpPost.nodes[0].postBlog.postFormat === 'Guide' && (
                  <BlogPostGuideTableOfContents
                    sections={data.allWpPost.nodes[0].postBlog.guide.sections}
                  />
                )}
              </div>
            </Col>
          </Row>
          <hr className="my-5" />
        </Container>

        {/* Related Escapes*/}
        {data.allWpPost.nodes[0].postBlog?.relatedEscapes && (
          <>
            <BlogPostRelatedEscapes
              escapes={data.allWpPost.nodes[0].postBlog.relatedEscapes}
            />
            <Container>
              <hr className="mt-5 mb-5" />
            </Container>
          </>
        )}

        {/* Related Posts*/}
        {data.allWpPost.nodes[0].postBlog?.relatedPosts && (
          <BlogPostRelatedPosts
            posts={data.allWpPost.nodes[0].postBlog.relatedPosts}
          />
        )}

        {/* Mailchimp Signup */}
        <section
          className={
            data.allWpPost.nodes[0].postBlog.relatedPosts ||
            data.allWpPost.nodes[0].postBlog.relatedEscapes
              ? 'pt-5 pt-md-6 pb-6 pb-md-7'
              : 'pt-4 pt-md-5 pb-6 pb-md-7'
          }
        >
          <NewsletterBanner />
        </section>
      </Styles>

      <ScrollTopButton showBelow={360} />

      <Share
        show={showShare}
        close={handleCloseShare}
        url={`${data.site.siteMetadata.siteUrl}/${globals.blogURI}${data.allWpPost.nodes[0].uri}`}
        type="Post"
        id={data.allWpPost.nodes[0].slug}
      />

      {data.allWpPost.nodes[0].postBlog.gallery.length > 1 && (
        <Gallery
          show={showGallery}
          close={handleCloseGallery}
          images={data.allWpPost.nodes[0].postBlog.gallery}
          title={data.allWpPost.nodes[0].title}
        />
      )}
    </Layout>
  );
};

export default PostTemplate;

export const query = graphql`
  query ($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        uri
        slug
        seo {
          title
          metaDesc
          metaKeywords
          canonical
          opengraphUrl
          opengraphType
          opengraphTitle
          opengraphDescription
          opengraphImage {
            localFile {
              publicURL
            }
          }
          twitterTitle
          twitterDescription
          twitterImage {
            localFile {
              publicURL
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        author {
          node {
            name
            description
            avatar {
              url
            }
            userAuthorProfile {
              website
              headshot {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 240
                      height: 240
                    )
                  }
                }
              }
            }
          }
        }
        categories {
          nodes {
            id
            name
            slug
          }
        }
        tags {
          nodes {
            id
            name
            slug
          }
        }
        geographies {
          nodes {
            id
            name
            slug
            wpParent {
              node {
                id
              }
            }
          }
        }
        template {
          templateName
        }
        postBlog {
          postFormat
          guide {
            sections {
              title
              content
            }
            conclusion
          }
          gallery {
            id
            altText
            caption
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                gatsbyImageData(layout: CONSTRAINED, width: 1920)
              }
            }
          }
          relatedPosts {
            post {
              ... on WpPost {
                ...cardPost
              }
            }
          }
          relatedEscapes {
            escape {
              ... on WpEscape {
                ...cardEscape
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
