import styled from '@emotion/styled';

import globals from '../../../../globals';
import * as colors from '../../../styles/modules/colors.module.scss';
import rhythm from '../../../utils/rhythm';

const Styles = styled.div({
  '.content': {
    display: 'flex',
    width: '100%',
    minHeight: `${globals.minHeaderHeight} !important`,

    '.container, .h1, a': {
      color: colors.white,
    },

    'a, span': {
      marginRight: rhythm(1 / 4),
    },

    span: {
      '::after': {
        content: '", "',
      },

      '&:last-of-type': {
        '::after': {
          content: '""',
        },
      },
    },

    i: {
      marginLeft: rhythm(1 / 4),
      marginRight: rhythm(1 / 2),
      fontStyle: 'normal',
    },
  },
});

export default Styles;
